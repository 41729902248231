import { Grid, Box, Typography, Divider, Paper, Button } from "@mui/material";
import React from "react";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import deck from '../../images/deck.jpeg';
import snow from '../../images/snow.jpeg';
import ev from '../../images/ev.jpeg';
import asphalt from '../../images/asphalt.jpeg';

import logoBig from '../../images/logoBig.png';
import qb from '../../images/qb.jpg';
import adobe from '../../images/adobe.png';
import microsoft from '../../images/microsoft.png';
import loginbg from '../../images/loginbg3.jpg';

import LoginLogic from "./loginLogic";

import Dexie from "dexie";
import 'dexie-observable';

import './style.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
      label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath:
        deck,
    },
    {
      label: 'Bird',
      imgPath:
        snow,
    },
    {
      label: 'Bali, Indonesia',
      imgPath:
        ev,
    },
    {
      label: 'Goč, Serbia',
      imgPath:
        asphalt,
    },
  ];
  

function NewLogin() {
    const { login, redirect } = LoginLogic();

    return(
        <Grid container>
            <Grid item xs={4}>
                <Box sx={{position: 'relative', height: '100%'}}>
                    <img src={loginbg} style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}} />
                    <Box sx={{position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center'}}>
                        <img src={logoBig} alt='Transblue Logo' style={{width: '200px', margin: '0 auto', marginBottom: '20px'}} />
                        <div className='login-wrapper'>
                            <h3 className='text-center' style={{zIndex: 45, color: 'white'}}>Transblue Connect</h3>
                            <Divider sx={{mt: 2, mb: 1, width: '75%', bgcolor: '#ed6a22'}} />
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                <Divider sx={{mb: 2, width: '75%', bgcolor: '#0b56a4', marginRight: '0px'}} />
                            </Box>
                            <Typography variant='subtitle1' sx={{color: 'rgba(255, 255,255, .8)'}}>Press the login button below to log in to your Transblue account</Typography>
                            {/* <Divider sx={{mt: 4, mb: 3}} /> */}
                            <Button
                                onClick={login}
                                variant='contained'
                                fullWidth
                                sx={{mt: 3}}
                            >
                                login
                            </Button>
                        </div>
                    </Box>

                    <Box sx={{position: 'absolute', bottom: '3%', left: '5%', width: '100%'}}>
                        <Typography variant='body2' sx={{mb: 1, color: 'rgba(255, 255, 255, .8)'}}>Integrations With</Typography>
                        <Box sx={{display: 'flex'}}>
                            <img src={qb} style={{height: '30px', marginRight: '10px'}} />
                            <img src={adobe} style={{height: '30px', marginRight: '10px'}} />
                            <img src={microsoft} style={{height: '30px'}} />
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{position: 'relative', width: '100%', height: '100%'}}>
                    <AutoPlaySwipeableViews>
                        {images.map((step, index) => (
                            <div key={step.label}>
                                <img
                                    style={{
                                        height: '100vh',
                                        display: 'block',
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                />
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <Box 
                        sx={{
                            position: 'absolute', 
                            top: '40%', 
                            left: '50%', 
                            zIndex: 50,
                            transform: 'translate(-50%, -60%)',
                            width: '100%',
                            textAlign: 'left',
                        }}
                    >
                        <Typography 
                            sx={{
                                fontSize: '5vh',
                                color: 'white',
                                paddingLeft: '5%'
                            }}>
                                WELCOME BACK TO
                        </Typography>
                        <Typography 
                            sx={{
                                fontSize: '9vh',
                                color: 'white',
                                paddingLeft: '5%',
                                letterSpacing: '.05em'
                            }}>
                                TRANSBLUE CONNECT
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '4vh',
                                paddingLeft: '5%',
                                color: 'white'
                            }}
                        >
                            Your portal for faster, more efficient bidding.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default NewLogin;