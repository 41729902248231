import { Button, Paper, TextField, Typography, InputAdornment, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, {useState} from 'react';

function Interview() {
    const [fruits, setFruits] = useState(['apple', 'banana', 'orange']);
    const [newFruit, setNewFruit] = useState('');

    function addFruit() {
        setFruits([newFruit, ...fruits]);
        setNewFruit('');
    }

    function test() {
        axios.get(`https://my-tb-cors.herokuapp.com/https://dev-fns.azurewebsites.net/api/getAll?containerId=projects&databaseId=dev`).then(res => console.log(res))
    }


    return (
        <Box sx={{width: '100%', height: '100%', position: 'absolute', bgcolor: 'gray'}}>
            <Button
                onClick={test}
            >
                test
            </Button>
            <Paper sx={{p: 3, position: 'relative', top: '50%', left: '50%', width: '400px', transform: 'translate(-50%,-50%)'}}>
                <Typography variant='h5'>Fruits List</Typography>

                <TextField
                    label='Fruit Name'
                    size='small'
                    onChange={(e) => setNewFruit(e.target.value)}
                    sx={{my: 2}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button onClick={addFruit} variant='contained' sx={{fontSize: '10px'}}>Add Fruit</Button>
                            </InputAdornment>
                        )
                    }}
                    value={newFruit}
                />
                {fruits.map(fruit => (
                    <li>{fruit}</li>
                ))}
            </Paper>
        </Box>
    )
}

export default Interview;