import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";

import PageLayoutLogic from "./pageLayoutLogic";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";

export default function PageLayout(props) {
  const history = useHistory();
  const { page } = props;
  const [location, setLocation] = React.useState(
    localStorage.getItem("location")
  );

  const {
    theme,
    open,
    AppBar,
    DrawerHeader,
    Drawer,
    navLinks,
    handleDrawerClose,
    handleDrawerOpen,
    logout,
  } = PageLayoutLogic();

  const { children } = props;

  useEffect(() => {
    let account = localStorage.getItem("account");
    if (!account) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    // force user to relogin after 24 hours
    let lastLoginTime = localStorage.getItem("lastLoginTime");
    let currentTime = new Date().getTime();
    if (currentTime - lastLoginTime > 86400000 || !lastLoginTime) {
      history.push("/");
    }
  }, []);

  function toggleLocation() {
    if (location === "Bellevue") {
      localStorage.setItem(
        "locationId",
        "db1a045c-7d60-4c2a-95fc-4e616bcb94a5"
      );
      localStorage.setItem("location", "Ballard");
      localStorage.setItem(
        "costguideId",
        "d156fffe-35f6-4fe2-891d-7185954a24dd"
      );
      setLocation("Ballard");
    } else {
      localStorage.setItem(
        "locationId",
        "3b3cdf06-950a-4e66-8343-60eacec1a7cc"
      );
      localStorage.setItem(
        "costguideId",
        "f82d833b-fcdd-4a44-acf3-aa7fb90edeb7"
      );
      localStorage.setItem("location", "Bellevue");
      setLocation("Bellevue");
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TransblueConnect
          </Typography>

          {(location === "Bellevue" || location === "Ballard") && (
            <>
              <Button
                sx={
                  location === "Bellevue"
                    ? { fontSize: "10px", bgcolor: "#ed6a22", mr: 1 }
                    : { fontSize: "10px", mr: 1 }
                }
                variant="contained"
                onClick={toggleLocation}
              >
                Bellevue
              </Button>
              <Button
                sx={
                  location === "Ballard"
                    ? { fontSize: "10px", bgcolor: "#ed6a22", mr: 1 }
                    : { fontSize: "10px", mr: 1 }
                }
                variant="contained"
                onClick={toggleLocation}
              >
                Ballard
              </Button>
            </>
          )}

          <Button
            sx={{ bgcolor: "white", color: "red", fontSize: "10px" }}
            variant="contained"
            component="a"
            target="_blank"
            href="https://forms.office.com/r/ycH44gLZa1"
          >
            FEEDBACK!!!
          </Button>
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={logo}
            alt=""
            style={{ display: "block", margin: "0 auto", width: "50%" }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navLinks.map((link, index) => (
            <ListItemButton
              component="a"
              href={link.href}
              key={link.text}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={
                  page === link.text
                    ? {
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#ed6a22",
                      }
                    : {
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }
                }
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText
                primary={link.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          bgcolor: "#f5f5f5",
          minHeight: "100vh",
          borderLeft: "1px solid #e4e5e7",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
