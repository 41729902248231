import React from 'react';

function BidScrubProposal() {
    let proposalHtml = localStorage.getItem('proposalHtml')

    return (
        <div style={{padding: '50px'}} dangerouslySetInnerHTML={{__html: proposalHtml}}></div>
    )
}

export default BidScrubProposal;