import React from "react";
import { ImageList, Button, ImageListItem } from "@mui/material";

function BidScrubImages() {
    const images = JSON.parse(localStorage.getItem('images'));

    function downloadImages() {
        images.forEach(image => {
            // download images to phone or computer
            // creating an invisible element
            var element = document.createElement('a');
            element.setAttribute('href', `${image}`);
            element.setAttribute('download', `${image}`);
            document.body.appendChild(element);
            
            // onClick property
            element.click();
        
            document.body.removeChild(element);
        })
    }

    return(
        <>
            <ImageList cols={3}>
                {images.map(image => {
                    return(
                        <ImageListItem key={image}>
                            <img src={image} alt='' />
                        </ImageListItem>
                    )
                })}
            </ImageList>

            <Button 
                sx={{fontSize: '10px'}}
                variant='contained'
                onClick={downloadImages}
            >
                download images
            </Button>
        </>
    )

}

export default BidScrubImages;