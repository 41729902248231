import { BrowserRouter as Router, Route} from 'react-router-dom';
import {useEffect, Suspense, lazy, useState }from 'react';
import * as msal from "@azure/msal-browser";
import { useClearCache } from 'react-clear-cache';

import Interview from './pages/interview/interview';
import BidScrubImages from './pages/bidScrubImages/bidScrubImages';
import Troubleshooting from './pages/troubleshooting/troubleshooting';

import { LicenseInfo } from '@mui/x-license-pro';
import BidScrubProposal from './pages/bidScrubProposal/bidScrubProposal';
import NewLogin from './pages/newLogin/newLogin';
import Test from './pages/quickbooks/test';
import { Box, Button, Modal, Typography } from '@mui/material';
import style from './styles/modal';

LicenseInfo.setLicenseKey('f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const Bids = lazy(() => import('./pages/bids/bids'));
const OpenBid = lazy(() => import('./pages/openBid/openBid'));
const CostGuide = lazy(() => import('./pages/costGuide/costGuide'));
const Franchises = lazy(() => import('./pages/franchises/franchises'));
const OpenFranchise = lazy(() => import('./pages/openFranchise/openFranchise'));
const Contacts = lazy(() => import('./pages/contacts/contacts'));
const OpenContact = lazy(() => import('./pages/openContact/openContact'));
const Proposals = lazy(() => import('./pages/proposals/proposals'));
const OpenProposal = lazy(() => import('./pages/openProposal/openProposal'));
const Organizations = lazy(() => import('./pages/organizations/organizations'));
const Reports = lazy(() => import('./pages/reports/reports'));
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'));
const Settings = lazy(() => import('./pages/settings/settings'));
const QueuePage = lazy(() => import('./pages/queue/queue'));
const QuickBooks = lazy(() => import('./pages/quickbooks/quickbooks'));
const Callback = lazy(() => import('./pages/quickbooks/callback'));
const Auth = lazy(() => import('./pages/auth/auth'));
const OpenOrganization = lazy(() => import('./pages/openOrganization/openOrganization'));
const View = lazy(() => import('./pages/view/view'));



const msalConfig = {
  auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENTID
      //clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID
  },
  cache: {
      cacheLocation: "localStorage",
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [hasSeenAlert, setHasSeenAlert] = useState(localStorage.getItem('hasSeenAlert') || 'false');

  async function getToken() {
    // get users microsoft account
    let account = localStorage.getItem('account');

    var request = {
      account: account
    };

    if(account) {
      let token = await msalInstance.acquireTokenSilent(request).then(tokenResponse => {
          localStorage.setItem('test token', tokenResponse.accessToken);
          // return access token from acquire silent token
          return tokenResponse.accessToken;
      }).catch(async (error) => {
          console.log(error)
          return msalInstance.acquireTokenPopop(request);
      }).catch(error => {
          return msalInstance.acquireTokenPopup(request);
          // handleError(error);
      });

      localStorage.setItem('connect token', token);
    }
  }

  useEffect(() => {
    if(!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [])

  const handleClose = () => {
    setHasSeenAlert('true');
    localStorage.setItem('hasSeenAlert', true)
  }

  return (
    <>
    <Modal
        open={hasSeenAlert !== 'true'}
        onClose={handleClose}
      >
        <Box sx={{...style, maxWidth: '500px', p: 5, textAlign: 'center'}}>
          <Typography variant='h5' sx={{mb: 2}}>
            Adobe PDF services is back up and running!
          </Typography>
          <Typography sx={{mb: 6}}>You can download and send Adobe pdfs</Typography>
          <Button
            onClick={handleClose}
            fullWidth
            variant='contained'
          >
              okay
          </Button>
        </Box>

      </Modal>

    <Suspense fallback={<div>Loading....</div>}>
      <Router>
        <Route exact path='/' component={NewLogin} />
        <Route exact path='/proposals' component={Bids} />
        <Route exact path='/proposals/:id' component={OpenBid} />
        <Route exact path='/proposals/:id/:component' component={OpenBid} />
        <Route exact path='/costguide/:id' component={CostGuide} />
        <Route exact path='/costguide' component={CostGuide} />
        <Route exact path='/franchises' component={Franchises} />
        <Route exact path='/franchises/:id' component={OpenFranchise} />
        <Route exact path='/contacts' component={Contacts} />
        <Route exact path='/contacts/:id' component={OpenContact} />
        <Route exact path='/sent' component={Proposals} />
        <Route exact path='/sent/:id' component={OpenProposal} />
        <Route exact path='/organizations' component={Organizations} />
        <Route exact path='/organizations/:id' component={OpenOrganization} />
        <Route exact path='/reports' component={Reports} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/settings/:id' component={Settings} />
        <Route exact path='/queue' component={QueuePage} />
        <Route exact path='/quickbooks' component={QuickBooks} />
        <Route exact path='/quickbooks/callback' component={Callback} />
        <Route exact path='/auth' component={Auth} />
        <Route exact path='/fruits' component={Interview} />
        <Route exact path='/bidScrubImages' component={BidScrubImages} />
        <Route exact path='/bidScrubProposal' component={BidScrubProposal} />
        <Route exact path='/troubleshooting' component={Troubleshooting } />
        <Route exact path='/test' component={Test} />
        <Route exact path='/view' component={View} />
      </Router>
    </Suspense>
    </>
  );
}

export default App;
