import { List, ListItem, Paper, Typography } from "@mui/material";
import React from "react";
import PageLayout from "../../components/pageLayout/pageLayout";

function Troubleshooting() {

    return(
        <PageLayout
            page='Troubleshooting'
        >
            <Paper sx={{p: 3}}>
                <Typography variant='h5'>Having trouble?</Typography>
                <Typography sx={{ mb: 2}}>Here are some things you can try:</Typography>
                <List sx={{pl: 4}}>
                    <ListItem sx={{display: 'list-item', listStyleType: 'decimal'}}>Try logging out and logging back in</ListItem>
                    <ListItem sx={{display: 'list-item', listStyleType: 'decimal'}}>Clear your browser cache - follow this link for directions to clear your browser cache on any major browser:</ListItem>
                    <ListItem inset><a href='https://www.lifewire.com/how-to-clear-cache-2617980' target='_blank' rel='noreferrer'>https://www.lifewire.com/how-to-clear-cache-2617980</a></ListItem>
                    <ListItem sx={{display: 'list-item', listStyleType: 'decimal'}}>Try in a private browser</ListItem>
                </List>
            </Paper>
        </PageLayout>
    )

}

export default Troubleshooting;