import { useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArticleIcon from '@mui/icons-material/Article';
import CalculateIcon from '@mui/icons-material/Calculate';
import { AttachMoney, Dashboard, LocationOn, PeopleAlt, QueryStats, Queue, Settings, CorporateFare, Info, Visibility } from '@mui/icons-material';

function PageLayoutLogic() {
    const history = useHistory();
    const drawerWidth = 240;
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const userId = localStorage.getItem('userId');
    const franchiseSupport = localStorage.getItem('franSupport');
    const franOwner = localStorage.getItem('franOwner');

    useEffect(() => {
        if(!userId) {
            history.push('/')
        }
    }, [])
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });
    
    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });
    
    const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    }));
      
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));
    
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
    );

    let navLinks = [
        {
            text: 'Dashboard',
            href: '/dashboard',
            icon: <Dashboard />
        },
        {
            text: 'Contacts',
            href: '/contacts',
            icon: <PeopleAlt />
        },
        {
            text: 'Organizations',
            href: '/organizations',
            icon: <CorporateFare />
        },
        {
            text: 'Proposals',
            href: '/proposals',
            icon: <ArticleIcon />
        },
        {
            text: 'Cost Guide',
            href: '/costguide',
            icon: <AttachMoney />
        },
        {
            text: 'Settings',
            href: `/settings/${userId}`,
            icon: <Settings />
        }
    ]

    if(franchiseSupport === 'true') {
        navLinks.splice(6, 0,
        {
            text: 'Quick Books',
            href: '/quickbooks',
            icon: <CalculateIcon />
        }, {
            text: 'Locations',
            href: '/franchises',
            icon: <LocationOn />
        }, {
            text: 'Queue',
            href: '/queue',
            icon: <Queue />
        }, {
            text: 'Reports',
            href: '/reports',
            icon: <QueryStats />
        },{
            text: 'View',
            href: '/view',
            icon: <Visibility />
        })
    }

    // only allow franchise owner to access quick books
    else if(franOwner === 'true') {
        navLinks = [...navLinks, {
            text: 'Quick Books',
            href: '/quickbooks',
            icon: <CalculateIcon />
        }]
    }

    navLinks = [
        ...navLinks,
        {
            text: 'Troubleshooting',
            href: '/troubleshooting',
            icon: <Info />
        }
    ]

    function logout() {
        localStorage.removeItem('msa');
        localStorage.removeItem('access token');
        localStorage.removeItem('user');
        localStorage.removeItem('email');

        history.push('/');
    }

    return {
        theme,
        open,
        AppBar,
        DrawerHeader,
        Drawer,
        navLinks,
        handleDrawerClose,
        handleDrawerOpen,
        logout
    }
}

export default PageLayoutLogic;